import { gql } from '@apollo/client';

export const QUERY_SLO_GET_SESSION = gql`
  query GetSession($sub: String!) {
    getSession(sub: $sub) {
      email
      logout
      sub
    }
  }
`;

export const MUTATION_SLO_UPDATE_SESSION = gql`
  mutation UpdateSession($logout: Boolean!, $sub: String!) {
    updateSession(input: { logout: $logout, sub: $sub }) {
      sub
      email
      logout
    }
  }
`;

export const MUTATION_SLO_DELETE_SESSION = gql`
  mutation DeleteSession($sub: String!) {
    deleteSession(input: { sub: $sub }) {
      sub
      email
      logout
    }
  }
`;

export const SUBSCRIPTION_SLO_DELETE_SESSION = gql`
  subscription OnDeleteSession($sub: String!) {
    onDeleteSession(sub: $sub) {
      email
      logout
      sub
    }
  }
`;
