/**
 *
 * Use these to check the node env.
 */
// Local.
export const isDevelopment = () => process.env.NODE_ENV === 'development';
// Nonprod (staging) & Prod.
export const isProduction = () => process.env.NODE_ENV === 'production';

/**
 *
 * Use these to check the server env.
 */
export const serverEnvName = process.env.NEXT_PUBLIC_SERVER_ENV;
export const isServerEnvProd = () => process.env.NEXT_PUBLIC_SERVER_ENV === 'prod';
export const isServerEnvNp = () => process.env.NEXT_PUBLIC_SERVER_ENV !== 'prod';

// Local & Nonprod (staging).
// @todo Deprecate this and move any isNonProd instances to use isServerEnvNp instead.
export const isNonProd = () => process.env.NEXT_PUBLIC_PAYPAL_ENV === 'sandbox';

// Launch Darkly.
export const ldReactClientId = () => process.env.NEXT_PUBLIC_LAUNCHDARKLY_SDK_CLIENT_SIDE_ID;

// SLO Logout.
export const sloLogoutKey = process.env.NEXT_PUBLIC_SLO_LOGOUT_KEY;
export const sloLogoutEndpoint = process.env.NEXT_PUBLIC_SLO_LOGOUT_ENDPOINT;
export const sloAwsRegion = process.env.NEXT_PUBLIC_SLO_LOGOUT_REGION;
