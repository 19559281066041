/**
 * @file Padi Single Logout Service.
 */
import { ApolloClient, InMemoryCache, ApolloLink, HttpLink, from, DefaultOptions } from '@apollo/client';
import { datadogLogs } from '@datadog/browser-logs';
import { sloLogoutKey, sloLogoutEndpoint } from 'helpers/utils/environment';
import { QUERY_SLO_GET_SESSION, MUTATION_SLO_UPDATE_SESSION, MUTATION_SLO_DELETE_SESSION } from './gql';

const authLink = new ApolloLink((operation, forward) => {
  operation.setContext(({ headers = {} }) => ({
    headers: {
      ...headers,
      'x-api-key': sloLogoutKey,
    },
  }));
  return forward(operation);
});

const httpLink = new HttpLink({ uri: `${sloLogoutEndpoint}` });

const defaultOptions: DefaultOptions = {
  watchQuery: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'ignore',
  },
  query: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'all',
  },
};

const createApolloClient = () => {
  return new ApolloClient({
    link: from([authLink, httpLink]),
    cache: new InMemoryCache(),
    defaultOptions,
  });
};

const apolloClient = createApolloClient();

export const GetSloSession = async (sub: string) => {
  if (!sub) return;
  try {
    const { data } = await apolloClient.query({
      query: QUERY_SLO_GET_SESSION,
      variables: { sub },
    });
    return data.getSession;
  } catch (err) {
    datadogLogs.logger.error('Error - GetSloSession()', {
      error: err,
      sub,
    });
  }
};

export const UpdateSloSession = async (logout: boolean, sub: string) => {
  try {
    const { data } = await apolloClient.mutate({
      mutation: MUTATION_SLO_UPDATE_SESSION,
      variables: {
        logout,
        sub,
      },
    });
    return data.updateSession;
  } catch (err) {
    datadogLogs.logger.error('Error - UpdateSloSession()', {
      error: err,
      sub,
    });
  }
};

export const DeleteSloSession = async (sub: string) => {
  try {
    const { data } = await apolloClient.mutate({
      mutation: MUTATION_SLO_DELETE_SESSION,
      variables: {
        sub,
      },
    });
    return data.updateSession;
  } catch (err) {
    datadogLogs.logger.error('Error - DeleteSloSession()', {
      error: err,
      sub,
    });
  }
};
